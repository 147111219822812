import React from "react";
import "../assets/styles/components/MainContainer.css";

const MainContainer = ({ bgImg, fAlign, fJustify, bgBlur, children }) => {
  return (
    <div className="main-container" style={{alignItems: `${fAlign}`, justifyContent: `${fJustify}`}}>

      {children}

      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${bgImg})`,
          filter: `blur(${bgBlur}px)`,
        }}
      ></div>
    </div>
  );
};

export default MainContainer;
