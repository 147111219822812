import React, { useState, useEffect } from "react";
import MainContainer from "../components/MainContainer";
import { changePlaceholder } from "../utils/FormUtils";
import { fetchCsrfToken } from "../utils/CommonUtils";
import axios from "axios";
import "../config/config";
import Cookies from "js-cookie";
import { useParams, useNavigate } from "react-router-dom";

//set background and stuff here
import bg from "../assets/media/bg/anime_school_paper.webp";

fetchCsrfToken();

let inputErrors = []; //submit button only enabled if this is empty.

const checkInviteCode = async (code, navigate) => {
  const response = await axios.get(
    `auth/check-invite-code/?invite_code=${code}`
  );

  if (response.data.status === "success") {
    // if invite code valid
  } else {
    // if invite code is NOT valid
    // navigate("/");
  }
};

const RegisterPage = () => {
  const navigate = useNavigate();
  const { inviteCode } = useParams();

  useEffect(() => {
    import("../assets/styles/pages/RegisterPage.css");
    document.title = "Enroll to Yaoi Academy!";

    // check if invite code is valid, if not, kick user out.

    checkInviteCode(inviteCode, navigate);
  }, [checkInviteCode]);

  // all the required inputs
  const [student_id, setStudentID] = useState("");
  const [student_name, setStudentName] = useState("");
  const [student_email, setStudentEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [date_of_birth, setDateOfBirth] = useState("");

  const changeToDateInput = (e) => {
    e.type = "date";
  };

  const submitEnrollment = async (event) => {
    event.preventDefault();
    //this function is called when submit button is clicked.

    if (inputErrors.length !== 0) {
      //disable submit if there is still input errors.
      alert("Please check if all fields are correctly filled!");
      return;
    }

    try {
      let formData = new FormData();
      formData.append("csrfmiddlewaretoken", Cookies.get("csrftoken"));
      formData.append("student_id", student_id);
      formData.append("student_name", student_name);
      formData.append("student_email", student_email);
      formData.append("password", password);
      formData.append("confirm_password", confirm_password);
      formData.append("date_of_birth", date_of_birth);
      formData.append("invite_code", inviteCode);

      const response = await axios.post("auth/enroll/", formData);

      if (response.data.status == "success") {
        navigate("/attend?enroll_success");
        return;

        let email_popup = document.getElementById("email-popup");
        email_popup.style.display = "flex";
      }
    } catch (error) {
      console.error("Oops! There has been an error: ", error);
      // Handle error if needed
    }
  };

  const validateEmail = async (event) => {
    event.preventDefault();

    navigate("/attend?enroll_success");
    return;
    //this function is called when submit button is clicked.

    let email_code_input = document.getElementById("email_code_input");
    let inputted_email_code = email_code_input.value;

    try {
      let formData = new FormData();
      formData.append("csrfmiddlewaretoken", Cookies.get("csrftoken"));
      formData.append("student_id", student_id);
      formData.append("inputted_email_code", inputted_email_code);

      const response = await axios.post("auth/validate-email/", formData);

      if (response.data.status === "success") {
        navigate("/attend?enroll_success");
      } else {
        email_code_input.value = "";
        changePlaceholder("That's the wrong code!", true)({target: email_code_input});
      }
    } catch (error) {
      console.error("Oops! There has been an error: ", error);
    }
  };

  const autoCapitalize = (elem) => {
    elem.value = elem.value.toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  }
  const checkInputValid = (elem) => {
    //this function checks if the input is valid. E.g: username must be more than 5 chara.
    let errorMsg;
    let error = false;
    const inputErrorElem = document.querySelector(
      `label[for="${elem.id}"] .inputError`
    );

    switch (elem.id) {
      case "studentID":
        if (elem.value.length <= 4) {
          error = true;
          errorMsg = "Username needs to be >4 characters.";
        }
        break;

      case "studentName":
        if (elem.value.length <= 3) {
          error = true;
          errorMsg = "Student Name needs to be >3 characters.";
        }

        const regex = /^[a-zA-Z\s]+$/; // This regex matches any digit (0-9)

        if(!regex.test(elem.value)){
          error = true;
          errorMsg = "Student Name can only contain normal alphabets.";
        }
        break;

      case "studentEmail":
        // Regular expression for validating email format
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(elem.value)) {
          error = true;
          errorMsg = "Please enter a valid email.";
        }
        break;

      case "password":
        if (elem.value.length <= 8) {
          error = true;
          errorMsg = "Password needs to be >8 characters.";
        }
        setConfirmPassword("");
        break;

      case "confirm_password":
        if (elem.value !== password) {
          error = true;
          errorMsg = "Make sure they're the same! ><";
        }
        break;
      case "date_of_birth":
        let dob = new Date(elem.value);
        let today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        let monthDiff = today.getMonth() - dob.getMonth();

        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < dob.getDate())
        ) {
          age--;
        }

        if (age < 18) {
          error = true;
          errorMsg = "You must be at least 18 years old.";
        }
        break;
      default:
        break;
    }

    if (error) {
      let errorCode = elem.id + "_invalid";
      if (!inputErrors.includes(errorCode)) {
        inputErrors.push(elem.id + "_invalid");
      }
      inputErrorElem.innerHTML = errorMsg;
    } else {
      inputErrorElem.innerHTML = "";
      inputErrors = inputErrors.filter((item) => item !== elem.id + "_invalid"); //remove error from inputErrors
    }
  };

  const checkEnrollDataExist = async (db_field, elem) => {
    //this function checks if a value already exists in the db. E.g: for username or email.
    try {
      const response = await axios.get(
        `auth/check-enroll-data-exist/?field=${db_field}&value=${elem.value}`
      );

      const inputErrorElem = document.querySelector(
        `label[for="${elem.id}"] .inputError`
      );

      if (response.data.alreadyExists) {
        let errorMsg;

        if (db_field === "username") {
          errorMsg = "This username already exists...";
        } else if (db_field === "email") {
          errorMsg = "This email is already in use! ><";
        }

        //add this error to inputErrors to be used in disabling submit button
        let errorCode = elem.id + "_exists";
        if (!inputErrors.includes(errorCode)) {
          inputErrors.push(elem.id + "_exists");
        }
        inputErrorElem.innerHTML = errorMsg;
      } else {
        if (!inputErrors.includes(elem.id + "_invalid")) {
          //dont clear if there is an error with the field still
          inputErrorElem.innerHTML = "";
        }

        inputErrors = inputErrors.filter(
          (item) => item !== elem.id + "_exists"
        ); //remove error from inputErrors
      }
    } catch (error) {
      console.error("Oops! There has been an error: ", error);
      // Handle error if needed
    }
  };

  return (
    <MainContainer bgImg={bg} bgBlur={0} fAlign="center" fJustify="center">
      <div className="yaui-ctn flex flexdir-col ai-center jf-center">
        <div id="header">
          <img
            id="ya-logo"
            src={require("../assets/media/logo/main_logo.png")}
            alt="Yaoi Academy Logo"
          ></img>
          <h2>
            Enrollment Form{" "}
            <m-only>
              <br></br>
            </m-only>
            for Yaoi Academy
          </h2>
        </div>

        {/* form */}
        <form className="yaui-form" onSubmit={submitEnrollment}>
          <span className="line-header">Student Information</span>

          <label htmlFor="studentID">
            Student ID <span className="inputError"></span>
          </label>
          <input
            id="studentID"
            type="text"
            value={student_id}
            onChange={(e) => {
              checkInputValid(e.target);
              setStudentID(e.target.value);
            }}
            placeholder="Choose a student ID."
            onFocus={changePlaceholder("This will be used for login.", true)}
            onBlur={(e) => {
              changePlaceholder("Choose a student ID.", false)(e);
              checkEnrollDataExist("username", e.target);
            }}
            autoComplete="off"
            required
          />

          <label htmlFor="studentName">
            Student Name <span className="inputError"></span>
          </label>
          <input
            id="studentName"
            type="text"
            value={student_name}
            onChange={(e) => {
              autoCapitalize(e.target);
              checkInputValid(e.target);
              setStudentName(e.target.value);
            }}
            placeholder="Enter your student name."
            onFocus={changePlaceholder("Don't use your real name!", true)}
            onBlur={changePlaceholder("Enter your student name.", false)}
            autoComplete="off"
            required
          />

          <label htmlFor="studentEmail">
            Student E-mail <span className="inputError"></span>
          </label>
          <input
            id="studentEmail"
            type="text"
            value={student_email}
            onChange={(e) => {
              checkInputValid(e.target);
              setStudentEmail(e.target.value);
            }}
            placeholder="Enter your valid e-mail address."
            onFocus={changePlaceholder(
              "Will need to be confirmed later.",
              true
            )}
            onBlur={(e) => {
              changePlaceholder("Enter your valid e-mail address.", false)(e);
              checkEnrollDataExist("email", e.target);
            }}
            autoComplete="off"
            required
          />

          <span className="line-header">Security Details</span>

          <label htmlFor="password">
            Password{" "}
            <div className="tooltip-container">
              (
              <span
                className="tooltip"
                style={{ color: "var(--col1)", textDecoration: "underline" }}
              >
                info
              </span>
              )
              <div className="tooltip-text">
                Passwords are securely encrypted!
              </div>
            </div>
            <span className="inputError"></span>
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => {
              checkInputValid(e.target);
              setPassword(e.target.value);
            }}
            placeholder="Choose a secure password."
            onFocus={changePlaceholder(
              ">8 characters with 1 special character.",
              true
            )}
            onBlur={changePlaceholder("Choose a secure password.", false)}
            autoComplete="off"
            required
          />

          <label htmlFor="confirm_password">
            Confirm password<span className="inputError"></span>
          </label>
          <input
            id="confirm_password"
            type="password"
            value={confirm_password}
            onChange={(e) => {
              checkInputValid(e.target);
              setConfirmPassword(e.target.value);
            }}
            placeholder="Enter password again."
            onFocus={changePlaceholder("Make sure its the same!", true)}
            onBlur={changePlaceholder("Enter password again.", false)}
            autoComplete="off"
            required
          />

          <label htmlFor="date_of_birth">
            Date of Birth<span className="inputError"></span>
          </label>
          <input
            id="date_of_birth"
            type="text"
            value={date_of_birth}
            onChange={(e) => {
              checkInputValid(e.target);
              setDateOfBirth(e.target.value);
            }}
            placeholder="Enter your real date of birth."
            onFocus={(e) => changeToDateInput(e.target)}
            autoComplete="off"
            required
          />

          <span
            style={{
              display: "block",
              marginTop: "1em",
              textAlign: "center",
              fontWeight: "800",
            }}
          >
            By clicking 'submit', you certify that you are at least 18 years of
            age.
          </span>
          <button id="submitBtn" className="btn1" type="submit">
            Submit
          </button>
        </form>
      </div>

      <div
        id="email-popup"
        className="yaui-ctn yaui-popup flexdir-col ai-center jf-center"
        style={{ display: "none" }}
      >
        <span className="line-header">Validate E-mail</span>
        <p>We've sent you a code to your e-mail address.</p>
        <form className="yaui-form" onSubmit={validateEmail}>
          <input
            id="email_code_input"
            type="text"
            placeholder="Enter it here!"
            style={{ textAlign: "center" }}
            autoComplete="off"
            required
          />
          <button className="btn1" type="submit" style={{ marginTop: "0" }}>
            Submit and Enroll!
          </button>
        </form>
      </div>
    </MainContainer>
  );
};

export default RegisterPage;
