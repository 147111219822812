export function yu_setTheme(themeName) {
  import(`../assets/styles/yaui/${themeName}.css`)
    .then(() => {
      // console.log(`Successfully imported ${themeName}.css`);
    })
    .catch((error) => {
      // console.error("Error importing CSS file:", error);
    });
}


////// unused cuz might affect user performance
// export function yaui(packedYAUIclass) {
//   var parts = packedYAUIclass.split(";");

//   var YAUIclasses = parts
//     .filter((part) => part !== "")
//     .map((part) => "yaui-" + part)
//     .join(" ");

//   console.log(YAUIclasses);
//   return YAUIclasses;
// }
