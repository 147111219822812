import React, { useState, useEffect } from "react";
import MainContainer from "../components/MainContainer";
import { appVer } from "../config/config";

//set background and stuff here
import bg from "../assets/media/bg/anime_school_entrance.webp";

const LoginPage = () => {
  useEffect(() => {
    import("../assets/styles/pages/LoginPage.css");
    document.title = "Attend Yaoi Academy";

    const getParam = new URLSearchParams(window.location.search);
    const enroll_sucess = getParam.get("enroll_success");

    if(enroll_sucess!==null){
      document.getElementById("enroll_success").style.display = "block";
    }
  }, []);

  const [student_id, setStudentID] = useState("");
  const [password, setPassword] = useState("");

  const attendAcademy = () => {
    // Implement your login logic here
    console.log("Logging in with:", { student_id, password });
    // You might use a login service, Redux actions, or other methods here
  };

  return (
    <MainContainer bgImg={bg} bgBlur={0} fAlign="center" fJustify={"center"}>
      <div className="yaui-ctn flex flexdir-col ai-center jf-center">
        {/* logo */}
        <img
          id="ya-logo"
          src={require("../assets/media/logo/main_logo.png")}
          alt="Yaoi Academy Logo"
        ></img>

        <span id="enroll_success" style={{textAlign:"center",color:"var(--colgreen)",fontWeight:"600",display:"none",paddingBottom:"1em"}}>Enrollment successful! <br /><br />The website is still under construction,<br />but you are now a registered member!</span>
        {/* form */}
        {/* <form className="yaui-form">
          <input
            type="text"
            value={student_id}
            onChange={(e) => setStudentID(e.target.value)}
            placeholder="Student ID"
          />
          <br />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <br />
          <button className="btn1" type="button" onClick={attendAcademy}>
            Attend
          </button>
        </form> */}
      </div>
      <span style={{ textAlign: "center" }}>
        Yaoi Academy v{appVer} © BLYA 2024
      </span>
    </MainContainer>
  );
};

export default LoginPage;
