import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./routes/AppRouter";
import { yu_setTheme } from "./utils/YAUIUtils";

//set theme css.
yu_setTheme("yaui-theme-cherrypop");


const App = () => {
  return (
    <Router>
        {/* Add any global components, layout, or navigation here */}
        <main className="yaui">
          <AppRouter />
        </main>
    </Router>
  );
};

export default App;
