import Cookies from 'js-cookie';
import axios from "axios";

export const fetchCsrfToken = async () => {
    try {
      const response = await axios.get("auth/get-csrf-token/");
      const csrfToken = response.data.csrfToken;
      Cookies.set('csrftoken', csrfToken, { expires: 3/24 });
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };