export const changePlaceholder = (text, focused) => (e) => {
  const input = e.target;
  console.log(input);
  input.placeholder = text;

  if (focused) {
    input.classList.add("hl-placeholder");
  } else {
    input.classList.remove("hl-placeholder");
  }
};
